<template>
    <div class="text-center">
        <v-overlay :model-value="overlay" class="align-center justify-center">
            <v-progress-circular color="black" indeterminate size="70" />
        </v-overlay>
    </div>
</template>
  
<script>
export default {
    props: {
        overlay: Boolean,
    },
};
</script>
  
  