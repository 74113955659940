import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const PageNotFound = () => import('@/components/PageNotFound.vue');
const ForgotPassword = () => import('@/views/auth/ForgotPasswordView.vue')
const ForgotPasswordReset = () => import('@/views/auth/ForgotPasswordResetView.vue')

const Dashboard = () => import('@/views/administrative/DashboardView.vue');
const Profile = () => import('@/views/user/ProfileView.vue');
const loggedIn = () => import('@/views/auth/LoginView.vue')

function importComponent(component) {
  if (component) {
    return () => import(`@/views/${component}.vue`);
  } else {
    return PageNotFound;
  }
}

async function loadRoutes(userType) {
  try {
    const moduleNamespace = userType === 'guest' ? 'guest' : 'user';
    await store.dispatch(`${moduleNamespace}/loadMenu${userType.charAt(0).toUpperCase() + userType.slice(1)}`);

    const menuConfig = store.getters[`${moduleNamespace}/getMenu${userType.charAt(0).toUpperCase() + userType.slice(1)}`];

    const importPromises = menuConfig.map(route => import(`@/views/${route.component}.vue`).catch(() => null));
    await Promise.all(importPromises);

    const routes = menuConfig.map(route => {
      const importedComponent = importComponent(route.component);

      if (importedComponent) {
        // If the route requires authentication, create the route normally
        return {
          path: route.path,
          name: route.name,
          component: importedComponent,
          meta: {
            requiresAuth: route.requiresAuth,
            title: route.name,
          } || {},
        };
      } else {
        console.warn(`Component '${route.component}' not found. Redirecting to Not Found.`);
        return {
          path: route.path,
          name: route.name,
          component: PageNotFound,
          meta: {
            requiresAuth: route.requiresAuth,
            title: 'Not Found',
          },
        };
      }
    });

    // Filter out null values (non-authenticated routes with missing components)
    const validRoutes = routes.filter(route => route !== null);

    return validRoutes;
  } catch (error) {
    console.error(`Error loading dynamic routes for ${userType} users:`, error);
    return []; // Return an empty array or another default value if needed
  }
}

async function loadLoggedInRoutes() {
  try {
    const routes = await loadRoutes('user');

    routes.forEach(route => {
      router.addRoute(route);
    });

    return routes;
  } catch (error) {
    console.error('Error loading dynamic routes for logged-in users:', error);
    throw error;
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      component: loggedIn,
      meta: {
        requiresAuth: false,
        title: 'Sign In',
      },
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
        title: 'Forgot password',
      },
    },
    {
      path: '/forgot-password-reset/:token',
      component: ForgotPasswordReset,
      meta: {
        requiresAuth: false,
        title: 'Reset new password',
      },
    },
    {
      path: '/',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
      },
    },
    {
      path: '/dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
      },
    },
    {
      path: '/profile',
      component: Profile,
      meta: {
        requiresAuth: true,
        title: 'User Profile',
      },
    },
    {
      path: '/:catchAll(.*)',
      component: PageNotFound,
      meta: {
        requiresAuth: false,
        title: 'Page not found',
      },
    }
  ],
});

try {
  const loggedIn = store.state.auth.status.loggedIn;
  if (loggedIn) {
    const dynamicRoutes = await loadLoggedInRoutes();
    dynamicRoutes.forEach(route => {
      router.addRoute(route);
    });
  }
} catch (error) {
  console.log(error);
}

// Add a navigation guard to check if the route requires authentication
router.beforeEach(async (to, from, next) => {
  const loggedIn = store.state.auth.status.loggedIn;

  if (loggedIn) {
    const dynamicRoutes = await loadLoggedInRoutes();
    dynamicRoutes.forEach(route => {
      // Pastikan rute belum ada sebelum menambahkannya
      if (!router.hasRoute(route.name)) {
        router.addRoute(route);
      }
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = !to.meta.requiresAuth || loggedIn;

    if (!isAuthenticated) {
      next('/login');
    } else {
      const pageTitle = to.meta.title || process.env.VUE_APP_NAME || 'My App';
      document.title = pageTitle;
      next();
    }
  } else {
    const pageTitle = to.meta.title || process.env.VUE_APP_NAME || 'My App';
    document.title = pageTitle;
    next();
  }

});

export default router;
