<template>
    <v-menu class="d-flex justify-space-around" v-for="(item, menuIndex) in getMenuGuest" :key="menuIndex"
        transition="slide-x-transition">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" v-if="item.path" :to="item.path">
                {{ item.name }}
            </v-btn>
            <v-btn v-bind="props" v-else>
                {{ item.name }}
            </v-btn>&nbsp;
        </template>
        <v-list v-if="item.children.length">
            <v-list-item v-for="childItem in item.children" :key="childItem.id">
                <v-list-item-title>
                    <v-btn variant="plain" :to="childItem.path">
                        {{ childItem.name }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
  
<script>
import { mapGetters } from 'vuex';

export default {
    name: "MenuListComponent",
    computed: {
        ...mapGetters('guest', ['getMenuGuest']),
    },
};
</script>
