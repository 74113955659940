<template>
    <v-list>
        <v-list-item :title="item.name" :subtitle="item.email">
            <template v-slot:prepend>
                <v-avatar>
                    <image-component :image-url="item.avatar" />
                </v-avatar>
            </template>
            <template v-slot:append>
                <v-tooltip text="Edit your profile" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" @click="$router.push('/profile')" size="small" variant="text"
                            icon="mdi-cog" />
                    </template>
                </v-tooltip>
            </template>
        </v-list-item>
    </v-list>
</template>
    
<script>
import api from "../services/api";
import ImageComponent from '@/components/ImageComponent.vue'

export default {
    name: "LogoComponent",
    data() {
        return {
            item: {},
        };
    },

    components: {
        ImageComponent
    },

    async mounted() {
        try {
            const response = await api.get("/user");
            this.item = response.data
        } catch (error) {
            console.log(error)
        }
    },
};
</script>
    