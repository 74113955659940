// Assuming you have a dataMenuUser file in your services directory
//import dataMenuUser from '@/services/menuUser';
import userService from "@/services/user.service";

const state = {
  menuUser: null,
};

const mutations = {
  setMenuUser(state, menuUser) {
    state.menuUser = menuUser;
  },
};

const actions = {
  async loadMenuUser({ commit, state }) {
    // Only fetch the menu data if it hasn't been loaded yet
    if (!state.menuUser) {
      try {
        const menuUser = await userService.getMenuUser()
        commit('setMenuUser', menuUser);
      } catch (error) {
        console.error('Error loading menu from User:', error);
      }
    }
  },
};

const getters = {
  getMenuUser: (state) => state.menuUser,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
