<template>
  <v-app>
    <v-navigation-drawer v-if="loggedIn" app v-model="drawer">
      <profile />
      <v-divider></v-divider>
      <MenuDrawer v-if="loggedIn" />
    </v-navigation-drawer>

    <v-app-bar color="teal-darken-4" image="https://picsum.photos/1920/1080?random">
      <template v-slot:image>
        <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
      </template>

      <template v-slot:prepend>
        <v-app-bar-nav-icon v-if="loggedIn" @click.stop="drawer = !drawer" />
      </template>

      <v-app-bar-title @click="this.$router.push('/')" style="cursor: pointer;">{{ appName }}</v-app-bar-title>

      <v-spacer></v-spacer>
      <menu-list v-if="!loggedIn" />
      <v-btn v-if="loggedIn" icon @click.prevent="$router.push('/dashboard')">
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <v-btn v-if="loggedIn" icon @click.prevent="logOut">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <overlay-component :overlay="overlayVisible" />
        <router-view />
        <vue-easy-lightbox :visible="lightboxVisible" :imgs="lightboxImgs" :index="lightboxIndex" @hide="hideLightbox" />
      </v-container>
    </v-main>

    <v-footer class="d-flex flex-column  justify-center">
      <div class="text-center py-5">
        {{ new Date().getFullYear() }} — <strong>{{ appName }}</strong>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { useTheme } from 'vuetify'
import { onMounted } from 'vue'
import { computed } from 'vue';
import { useStore } from 'vuex';

import EventBus from "./common/EventBus"
import MenuList from "@/components/MenuListComponent"
import MenuDrawer from "@/components/MenuDrawerComponent"
import Profile from "@/components/ProfileComponent.vue"
import OverlayComponent from '@/components/OverlayComponent.vue';


export default {
  setup() {
    const theme = useTheme();

    const toggleTheme = () => {
      theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
      localStorage.setItem('theme', theme.global.name.value);
    };

    onMounted(() => {
      const storedTheme = localStorage.getItem('theme');
      if (storedTheme !== null) {
        theme.global.name.value = storedTheme;
      }
    });

    const store = useStore();

    const lightboxVisible = computed(() => store.state.lightbox.lightboxVisible);
    const lightboxImgs = computed(() => store.state.lightbox.lightboxImgs);
    const lightboxIndex = computed(() => store.state.lightbox.lightboxIndex);
    const hideLightbox = () => store.commit('lightbox/hideLightbox');

    return {
      theme,
      toggleTheme,
      lightboxVisible,
      lightboxImgs,
      lightboxIndex,
      hideLightbox,
    };
  },

  data() {
    return {
      drawer: null,
      appName: null,
    };
  },

  components: {
    MenuList, MenuDrawer, Profile, OverlayComponent
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    overlayVisible() {
      return this.$store.state.overlay.showOverlay;
    },
  },

  methods: {
    logOut() {
      this.$router.push("/login");
      this.$store.dispatch('auth/logout');
    },
  },

  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },

  beforeUnmount() {
    EventBus.remove("logout");
  },

  created() {

    this.appName = process.env.VUE_APP_NAME
  }

};
</script>


 