// store/overlay.js
const state = {
    showOverlay: false,
  };
  
  const mutations = {
    openOverlay(state) {
      state.showOverlay = true;
    },
    hideOverlay(state) {
      state.showOverlay = false;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
  };
  