// store/index.js
import { createStore } from 'vuex';
import guestModule from './guest.module';
import userModule from './user.module';
import { auth } from './auth.module';
import lightboxModule from './lightbox.module';
import overlayModule from './overlay.module'; // Add this line

const store = createStore({
  modules: {
    auth,
    guest: guestModule,
    user: userModule,
    lightbox: lightboxModule,
    overlay: overlayModule,
  },
});

export default store;
