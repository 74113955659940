import api from './api';
import TokenService from "./token.service";

class UserService {

  async getMenuGuest() {
    let response = await api.get('/menu')
    return response.data
  }

  async getMenuUser() {
    const response = await api.get("/menu-user", {
      headers: {
        Authorization: `Bearer ${TokenService.getUser()}`,
      },
    });

    return response.data
  }

}

export default new UserService();
