import api from "./api"
import TokenService from "./token.service"

class AuthService {

  async login(form) {
    const response = await api.post("/login", form)

    if (response.data.accessToken) {
      TokenService.setUser(response.data.accessToken)
    }

    return response.data
  }

  async verifyGoogleLogin(googleUser) {
    
    const response = await api.post('/login-google', googleUser)
    if (response.data.accessToken) {
      TokenService.setUser(response.data.accessToken)
    }

    return response.data
  }

  async verifyGoogleRegister(googleUser) {
    const response = await api.post('/register-google', googleUser)

    if (response.data.accessToken) {
      TokenService.setUser(response.data.accessToken)
    }

    return response.data
  }

  logout() {
    TokenService.removeUser()
  }

  register({ name, email, password }) {
    return api.post("/auth/signup", {
      name,
      email,
      password
    })
  }
}

export default new AuthService()