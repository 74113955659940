import axiosInstance from "./api";
import TokenService from "./token.service";
import router from "@/router";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getUser();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const status = error.response.status;

        if (status === 403) {
          // Handle 403 Forbidden error, for example, redirect to a forbidden page
          router.push('/login');
        }
      }

      return Promise.reject(error);
    }
  );

  
};

export default setup;