import CryptoJS from 'crypto-js';

const secretKey = 'iniSecretKeyAnda'; // Ganti dengan secret key yang aman

class TokenService {

  encryptData(data) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
  }

  decryptData(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalData);
  }

  getUser() {
    const encryptedUser = localStorage.getItem("OAT");
    if (encryptedUser) {
      return this.decryptData(encryptedUser);
    }
    return null;
  }

  setUser(user) {
    const encryptedData = this.encryptData(user);
    localStorage.setItem("OAT", encryptedData);
  }

  removeUser() {
    localStorage.removeItem("OAT");
  }
}

export default new TokenService();
