// store/modules/lightbox.js
const state = {
  lightboxVisible: false,
  lightboxImgs: [],
  lightboxIndex: 0,
};

const mutations = {
  openLightbox(state, payload) {
    state.lightboxVisible = true;
    state.lightboxImgs = [{ src: payload.imageUrl }];
    state.lightboxIndex = 0;
  },

  hideLightbox(state) {
    state.lightboxVisible = false;
  },
};

export default {
  namespaced: true, // Pastikan modul ini memiliki namespace
  state,
  mutations,
};
