// Assuming you have a dataMenuGuest file in your services directory
//import dataMenuGuest from '@/services/menuGuest';
import userService from '@/services/user.service';
const state = {
  menuGuest: null,
};

const mutations = {
  setMenuGuest(state, menuGuest) {
    state.menuGuest = menuGuest;
  },
};

const actions = {
  async loadMenuGuest({ commit, state }) {
    // Only fetch the menu data if it hasn't been loaded yet
    if (!state.menuGuest) {
      try {
        const menuGuest = await userService.getMenuGuest();
        commit('setMenuGuest', menuGuest);
      } catch (error) {
        console.error('Error loading menu from guest:', error);
      }
    }
  },
};

const getters = {
  getMenuGuest: (state) => state.menuGuest,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
