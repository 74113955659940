<template>
  <v-list dense nav v-if="loggedIn">
    <v-list-item v-for="item in getMenuUser" :key="item.id" :to="item.path" :prepend-icon="item.icon">
      {{ item.name }}
    </v-list-item>
  </v-list>
</template>
  
<script>
import { mapGetters } from 'vuex';

export default {
  name: "MenuDrawerComponent",

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    ...mapGetters('user', ['getMenuUser']),
  },
};
</script>
  