<!-- ImageComponent.vue -->
<template>
  <v-img v-if="imageUrl" :src="imageUrl" :lazy-src="imageUrl" @click="openLightbox" class="cursor-pointer">
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate />
      </v-row>
    </template>
  </v-img>
  <v-img v-else src="@/assets/gif/image-not-found.gif" />
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
  },
  methods: {
    openLightbox() {
      this.$store.commit('lightbox/openLightbox', { imageUrl: this.imageUrl });
    },
  },
};
</script>
