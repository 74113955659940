// main.js

import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';

import { loadFonts } from './plugins/webfontloader';
import setupInterceptors from './services/setupInterceptors';
import { VueReCaptcha } from "vue-recaptcha-v3";
import AOS from "aos";
import "aos/dist/aos.css";

import toast from 'vue-toastification';
import "./assets/custom-toast-styles.css";

import VueEasyLightbox from 'vue-easy-lightbox'
import vue3GoogleLogin from 'vue3-google-login'

import "./assets/custom-separate.css"
import "@cryptofonts/cryptofont/cryptofont.css";
import './registerServiceWorker'

loadFonts();
setupInterceptors(store);
AOS.init();

const app = createApp(App);

app.use(vuetify)
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: {
      autoHideBadge: true,
    },

  })
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
  })
  .use(toast)
  .use(store)
  .use(router)
  .use(VueEasyLightbox)
  .mount('#app');
